// This file must be in CommonJS format since it is imported in gatsby-node.js

const { linkResolverArrayPathFor } = require('@walltowall/helpers')

exports.linkResolver = () => (doc) => {
  let path = linkResolverArrayPathFor(doc)

  switch (doc.type) {
    case 'project':
      path = ['projects', ...path]
      break

    case 'closure':
      path = ['closures', ...path]
      break
  }

  return [null, ...path].join('/')
}
