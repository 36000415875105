import React from 'react'
import { PreviewTemplate } from '@walltowall/gatsby-theme-ww-prismic'

import { linkResolver } from 'src/linkResolver'

export const PreviewPage = (props) => (
  <PreviewTemplate linkResolver={linkResolver} {...props} />
)

export default PreviewPage
